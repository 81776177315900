import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './NewsPage.css';

function openInNewTab(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

function NewsPage() {
  return (
    <div className='hero-containern'>
      <h1>In the News</h1>
      <p>Our Stories.</p>
      <div className='logo-btnsn'>
        <Link to="/n14">
          <img src={'/images/NYTimes.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n13">
          <img src={'/images/NYTimes.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n12">
          <img src={'/images/logo-the-street.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n9">
          <img src={'/images/CBS.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n8">
          <img src={'/images/CBS.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n10">
          <img src={'/images/peoplelogo.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n11">
          <img src={'/images/The-Guardian-logo.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n1">
          <img src={'/images/southchinamorningpost.png'} alt="Description" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }} /> 
        </Link>
        <Link to="/n15">
            <img src={'/images/scripps_news_logo.png'} alt="The Street" style={{ borderRadius: 5, background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>
          <Link to="/n12">
            <img src={'/images/techcrunch-logo.png'} alt="The Street" style={{ borderRadius: 5, background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>
          <Link to="/n12">
            <img src={'/images/click-here-logo.jpeg'} alt="The Street" style={{ borderRadius: 5, background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>
      </div>

      <h2><br /></h2>
    </div>
  );
}

export default NewsPage;
