import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  padding: 4rem 2rem; /* Balanced padding for all devices */
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
`;

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Adjusted for larger logos */
  gap: 30px; /* Increased gap for better spacing */
  justify-items: center; /* Center items in grid */
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Smaller columns for mobile */
    gap: 20px; /* Reduced gap for smaller screens */
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* More rows for very small screens */
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LogoImage = styled.img`
  width: 100%;
  max-width: 250px; /* Larger logos */
  height: auto;
  background: white;
  border-radius: 5px;
  padding: 15px; /* Add padding for a polished look */

  @media (max-width: 768px) {
    max-width: 200px; /* Adjust logo size for tablets */
    padding: 10px;
  }

  @media (max-width: 576px) {
    max-width: 150px; /* Smaller logos for mobile */
    padding: 8px;
  }
`;

const YellowButton = styled(Link)`
  display: inline-block;
  padding: 14px 28px;
  background-color: #FBB243;
  color: #333;
  text-decoration: none;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #EAA835; /* Slightly darker on hover */
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust button font size for mobile */
    padding: 12px 24px;
  }
`;

const AlectoAIDescription = () => {
  return (
    <Container>
      <Heading>Press Coverage</Heading>
      <LogoGrid>
        <LogoLink to="/n14">
          <LogoImage src="/images/NYTimes.png" alt="NYTimes" />
        </LogoLink>
        <LogoLink to="/n13">
          <LogoImage src="/images/NYTimes.png" alt="NYTimes" />
        </LogoLink>
        <LogoLink to="/n9">
          <LogoImage src="/images/CBS.png" alt="CBS" />
        </LogoLink>
        <LogoLink to="/n8">
          <LogoImage src="/images/CBS.png" alt="CBS" />
        </LogoLink>
        <LogoLink to="/n10">
          <LogoImage src="/images/peoplelogo.png" alt="People" />
        </LogoLink>
        <LogoLink to="/n15">
          <LogoImage src="/images/sbslogo.png" alt="SBS" />
        </LogoLink>
        <LogoLink to="/n11">
          <LogoImage src="/images/The-Guardian-logo.png" alt="The Guardian" />
        </LogoLink>
        <LogoLink to="/n1">
          <LogoImage src="/images/southchinamorningpost.png" alt="SCMP" />
        </LogoLink>
        <LogoLink to="/n12">
          <LogoImage src="/images/logo-the-street.png" alt="The Street" />
        </LogoLink>
        <LogoLink to="/n15">
          <LogoImage src="/images/scripps_news_logo.png" alt="Scripps" />
        </LogoLink>
        <LogoLink to="/n12">
          <LogoImage src="/images/techcrunch-logo.png" alt="TechCrunch" />
        </LogoLink>
      </LogoGrid>
      <YellowButton to="/news">
        Learn more about our story through press
      </YellowButton>
    </Container>
  );
};

export default AlectoAIDescription;
